<template>
  <div class="classNumDetails">
    <el-scrollbar class="elScroll">
      <template v-if="$route.name=='classNumDetails'">
        <div class="search_sList">
          <!-- <el-tabs v-model="activeName">
            <el-tab-pane
              label="专业数详情"
              name="first"
            ></el-tab-pane>
          </el-tabs> -->
          <div class="top_opera">
            <el-form
              :inline="true"
              :model="formInline"
              class="demo-form-inline"
              label-width="80px"
            >
              <el-form-item label="班级标题">
                <el-input
                  v-model="formInline.name"
                  placeholder="班级标题"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="发布状态">
                <el-select v-model="formInline.releaseStatus" placeholder="发布状态" filterable clearable>
                  <el-option label="未发布" value="1"></el-option>
                  <el-option label="已发布" value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div class="btn_area">
              <el-button
                class="mainBtn"
                @click="onSubmit"
                plain
                v-preventReClick
                >搜索</el-button
              >
              <el-button type="info" @click="resetEvent" plain>重置</el-button>
            </div>
          </div>
        </div>
        <div class="table_sList">
          <!-- <div class="opera_btnArea">
            <el-button class="mainBtn" @click="classEvent('add')" plain v-if="$route.query.subjectCode">添加班级</el-button>
          </div> -->
          <el-table
            :data="tableData"
            stripe
            border
            style="width: 100%"
            align="center"
          >
            <el-table-column
              prop="name"
              label="班级标题"
              align="center"
            >
              <template slot-scope="scope">
                <el-tooltip
                  :content="scope.row.name"
                  placement="top"
                  effect="light"
                  popper-class="pubToop"
                >
                  <div class="ellipsis">{{ scope.row.name || '/' }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="type"
              label="助学分类"
              align="center"
              :formatter="regType"
            >
            </el-table-column>
            <el-table-column
              prop="artsSciences"
              label="文理属性"
              align="center"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.artsSciences">{{scope.row.artsSciences == 1 ? '理科' : '文科'}}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="releaseStatus"
              label="发布状态"
              align="center"
              width="80"
            >
              <template slot-scope="scope">
                <el-switch
                  :active-value="2"
                  :inactive-value="1"
                  @change="chageReleaseStatus(scope.row)"
                  v-model="scope.row.releaseStatus">
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column
              label="课件"
              align="center"
              width="60"
            >
              <template slot-scope="scope">
                <!-- <el-tooltip
                  :content="scope.row.coursewareCount"
                  placement="top"
                  effect="light"
                  popper-class="pubToop"
                >
                  <div class="ellipsis">{{ scope.row.coursewareCount || '/' }}</div>
                </el-tooltip> -->
                <el-button type="text" size="small" @click="jumpCourse(scope.row)" v-if="$isShowModle('tch_aid_class_resource')">{{ scope.row.coursewareCount || '0' }}</el-button>
                <span v-else>{{ scope.row.coursewareCount || '0' }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="专项练习"
              align="center"
              width="80"
            >
              <template slot-scope="scope">
                <!-- <el-tooltip
                  :content="scope.row.specialExercisesCount"
                  placement="top"
                  effect="light"
                  popper-class="pubToop"
                > -->
                  <!-- <el-button type="text" size="small" @click="jumpSpecial(scope.row)" v-if="$isShowModle('tch_aid_class_resource')">{{scope.row.specialExercisesIds ? scope.row.specialExercisesIds.split(',').length : 0}}</el-button>
                  <span v-else>{{scope.row.specialExercisesIds ? scope.row.specialExercisesIds.split(',').length : 0}}</span> -->
                  <!-- <span class="ellipsis">{{ scope.row.specialExercisesIds ? scope.row.specialExercisesIds.split(',').length : '/' }}</span> -->
                <!-- </el-tooltip> -->
                <el-button type="text" size="small" @click="jumpSpecial(scope.row)" v-if="$isShowModle('tch_aid_class_resource')">{{scope.row.specialExercisesCount}}</el-button>
                <span v-else>{{scope.row.specialExercisesCount}}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="realPaperIds"
              label="真题精编"
              align="center"
              width="80"
            >
              <template slot-scope="scope">
                <!-- <el-button type="text" size="small" @click="jumpPaper(scope.row)" v-if="$isShowModle('tch_aid_class_resource')">{{scope.row.realPaperIds ? scope.row.realPaperIds.split(',').length : 0}}</el-button>
                <span v-else>{{scope.row.realPaperIds ? scope.row.realPaperIds.split(',').length : 0}}</span> -->
                <el-button type="text" size="small" @click="jumpPaper(scope.row)" v-if="$isShowModle('tch_aid_class_resource')">{{scope.row.realPaperCount}}</el-button>
                <span v-else>{{scope.row.realPaperCount}}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="mockPaperIds"
              label="模拟考试"
              align="center"
              width="80"
            >
              <template slot-scope="scope">
                <!-- <el-button type="text" size="small" @click="jumpMockPaper(scope.row)" v-if="$isShowModle('tch_aid_class_resource')">{{scope.row.mockPaperIds ? scope.row.mockPaperIds.split(',').length : 0}}</el-button>
                <span v-else>{{scope.row.mockPaperIds ? scope.row.mockPaperIds.split(',').length : 0}}</span> -->
                <el-button type="text" size="small" @click="jumpMockPaper(scope.row)" v-if="$isShowModle('tch_aid_class_resource')">{{scope.row.mockPaperCount}}</el-button>
                <span v-else>{{scope.row.mockPaperCount}}</span>
              </template>
            </el-table-column>
            <!-- <el-table-column
              label="学习人数"
              align="center"
              width="80"
            >
              <template slot-scope="scope">
                <el-button type="text" @click="jumpStudyNum(scope.row)">{{scope.row.learnCount}}</el-button>
              </template>
            </el-table-column> -->
            <el-table-column
              prop="createTime"
              label="添加时间"
              align="center"
              width="140"
            >
              <template slot-scope="scope">
                <span>{{timestampReturnDate(scope.row.createTime, 'datetime') || '/'}}</span>
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              label="操作"
              width="170"
              align="center">
              <template slot-scope="scope">
                <el-button type="text" @click="classEvent('edit', scope.row)" v-if="$route.query.subjectCode">编辑</el-button>
                <el-button type="text" @click="jumpStudyNum(scope.row)">查看学生</el-button>
                <el-button type="text" @click="dialogDelDiyEvent(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <Pagination
            @changePage="changePage"
            :childMsg="childMsg"
          ></Pagination>
        </div>
        <Keydown @keydownEvent='handleKeydown'></Keydown>
        <dialogDiy :isShow="isShowClass" :dialogDiyData="dialogClassDiyData"
        @dialogEvent="dialogClassEvent">
          <div slot="dialogContent" class="class_area">
            <el-form :inline="true" :model="classForm" class="demo-form-inline" label-width="100px" :rules="rules">
              <el-form-item label="班级标题" prop="name">
                <el-input
                  v-model="classForm.name"
                  placeholder="班级标题"
                  clearable
                ></el-input>
              </el-form-item>
            </el-form>
            <div class="btn_area">
              <el-button @click="dialogClassEvent">取消</el-button>
              <el-button type="primary" @click="enterEvent">确认</el-button>
            </div>
          </div>
        </dialogDiy>

        <dialogDiy
          :isShow="isShowDel"
          :dialogDiyData="dialogDelDiyData"
          @dialogEvent="isShowDel = false"
        >
          <div slot="dialogContent" class="dia_opera">
            <img class="errIcon" src="../../../assets/img/warn.png" alt="" />
            <p>确定要删除该班级详情吗？</p>
            <div class="btn_area">
              <el-button @click="isShowDel = false">关闭</el-button>
              <el-button class="mainBtn" @click="doEvent">确定</el-button>
            </div>
          </div>
        </dialogDiy>
      </template>
      <template v-else>
        <router-view></router-view>
      </template>
    </el-scrollbar>
  </div>
</template>

<script>
import way from '../../../api/encapsulation'
import Pagination from '../../Pagination'
import publicChildren from '../../publicChildren'
import dialogDiy from '../../commonModule/dialogDiy'
export default {
  name: 'classNumDetails',
  components: {
    Pagination,
    publicChildren,
    dialogDiy
  },
  data() {
    return {
      publishStatus: [],
      formInline: {
        eduCategory: null,
        name: null,
        page: 1,
        pageSize: 10,
        releaseStatus: null,
        subjectCode: null,
        type: null
      },
      tableData: [],
      childMsg: {
        current: 1,
        size: 10,
        total: 1,
      },
      value: true,
      isShowClass: false,
      dialogClassDiyData: {
        title: null,
        sizeStyle: {width: '400px',height: '250px'}
      },
      classForm: {
        name: null,
        eduCategory: null,
        id: null,
        subjectCode: null,
        type: null
      },
      rules: {
        name: [
          { required: true, message: "请输入班级标题", trigger: "blur" }
        ]
      },
      type: null,
      isShowDel: false,
      dialogDelDiyData: {
        title: null,
        sizeStyle: {width: '400px',height: '234px'}
      },
      rowData: null
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal.name == "classNumDetails") {
          this.getQuery(newVal)
          this.getAidClassManagementData()
        }
      },
    },
  },
  methods: {
    timestampReturnDate: way.timestampReturnDate,
    handleKeydown(event) {
      if (!this.isShowClass&&!this.isShowDel) {
        this.onSubmit()
      }
    },
    getQuery(newVal) {
      this.formInline.type = newVal.query.type;
      this.formInline.eduCategory = newVal.query.eduCategory;
      this.formInline.subjectCode = newVal.query.subjectCode;
      this.classForm.type = newVal.query.type;
      this.classForm.eduCategory = newVal.query.eduCategory;
      this.classForm.subjectCode = newVal.query.subjectCode;
    },
    // 搜索
    onSubmit() {
      this.formInline.page = 1;
      this.formInline.pageSize = 10;
      this.getAidClassManagementData();
    },
    // 重置
    resetEvent() {
      this.formInline.eduCategory = null
      this.formInline.name = null
      this.formInline.page = 1
      this.formInline.pageSize = 10
      this.formInline.releaseStatus = null
      this.formInline.subjectCode = null
    },
    regType(row) {
      let typeVal = row.type == 1 ? '成考考前辅导' : ''
      let eduVal = row.eduCategory == '0' ? '专升本' : row.eduCategory == 1 ? '高起本' : '高起专'
      let val = row.eduCategory == '0' ? `${typeVal}/${eduVal}/${row.subjectName}` : `${typeVal}/${eduVal}`;
      return val;
    },
    // 获取班级表格数据
    getAidClassManagementData() {
      let obj = this.formInline;
      this.$request.aidClassManagementData(obj).then(res => {
        if (res.data.code == 0) {
          let { records, current, size, total } = res.data.data;
          this.tableData = records;
          this.childMsg = {
            current,
            size,
            total
          };
        }
      });
    },
    changePage(val) {
      this.childMsg = val;
      this.formInline.page = val.current;
      this.formInline.pageSize = val.size;
      this.getAidClassManagementData();
    },
    dialogClassEvent() {
      this.isShowClass = !this.isShowClass;
    },
    classEvent(type, row) {
      this.type = type;
      this.dialogClassDiyData.title = type == 'add' ? '添加班级' : '编辑班级'
      this.classForm.id = type == 'add' ? null : row.id;
      this.classForm.name = type == 'add' ? null : row.name;
      this.dialogClassEvent()
    },
    enterEvent() {
      if(!this.classForm.name) {
        this.Warn('班级标题未填');
        return;
      }
      this.$request.changeClass(this.classForm).then(res => {
        if (res.data.code == 0) {
          this.Success(res.data.msg);
          this.classForm.name = null;
          this.getAidClassManagementData();
          this.dialogClassEvent();
        }
      });
    },
    chageReleaseStatus(row) {
      let obj = {
        id: row.id,
        status: row.releaseStatus
      }
      this.$request.publishClass(obj).then(res => {
        if(res.data.code == 0) {
          this.Success(res.data.msg);
        } else if(res.data.code == 1) {
          row.releaseStatus = row.releaseStatus == 1 ? 2 : 1
        }
      }).catch(err => {
        row.releaseStatus = row.releaseStatus == 1 ? 2 : 1
      })
    },
    dialogDelDiyEvent(row) {
      this.rowData = row;
      this.isShowDel = !this.isShowDel;
    },
    doEvent() {
      this.$request.deleteAidClass({id: this.rowData.id}).then(res => {
        if (res.data.code == 0) {
          this.Success(res.data.msg);
          this.getAidClassManagementData();
          this.isShowDel = !this.isShowDel;
        }
      });
    },
    jumpStudyNum(row) {
      this.$router.push({name: 'studyNumDetails', query: {
        eduCategory: row.eduCategory,
        subjectCode: row.subjectCode,
        artsSciences: row.artsSciences,
        aidClassId: row.id
      }})
    },
    jumpCourse(row) {
      this.$router.push({name: 'coursewareDetails', query: {
        aidClassId: row.id,
        breadcrumb: '助学管理,助学分类,班级数详情'
      }})
    },
    jumpSpecial(row) {
      sessionStorage.setItem('fatherRoute', this.$route.fullPath)
      this.$router.push({name: 'specialExercises', query: {
        aidClassId: row.id,
        // ids: row.specialExercisesIds,
        breadcrumb: '助学管理,助学分类,班级数详情'
      }})
    },
    jumpPaper(row) {
      sessionStorage.setItem('fatherRoute', this.$route.fullPath)
      this.$router.push({name: 'refinedEdition', query: {
        aidClassId: row.id,
        // ids: row.realPaperIds,
        // year: row.year,
        type: 3,
        headTitle: '真题精编详情',
        breadcrumb: '助学管理,助学分类,班级数详情'
      }})
    },
    jumpMockPaper(row) {
      sessionStorage.setItem('fatherRoute', this.$route.fullPath)
      this.$router.push({name: 'refinedEdition', query: {
        aidClassId: row.id,
        // ids: row.mockPaperIds,
        type: 1,
        headTitle: '模拟考试详情',
        breadcrumb: '助学管理,助学分类,班级数详情'
      }})
    },
  },
}
</script>

<style lang='less'>
  .classNumDetails {
    height: 100%;
    .search_sList {
      background-color: #fff;
      padding: 28px 40px;
      .top_opera {
        display: flex;
        .el-form {
          flex: 4;
          .el-form-item__content {
            width: 218px;
          }
        }
        .btn_area {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .table_sList {
      box-sizing: border-box;
      padding: 30px 40px;
      margin-top: 10px;
      background-color: #fff;
      .opera_btnArea {
        margin-bottom: 20px;
      }
      ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background-color: #fff;
      }
      ::-webkit-scrollbar-thumb {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
          background-color: rgba(0, 0, 0, .1);
          border-radius: 3px;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
      }
      .el-table__header .has-gutter th.is-leaf:nth-last-child(2) {
          border-right: none;
      }
      .el-dropdown-link {
        cursor: pointer;
        color: #409EFF;
      }
      .el-icon-arrow-down {
        font-size: 12px;
      }
      .el-pagination {
        text-align: right;
      }
    }
    .class_area {
      height: calc(~'100% - 70px');
      box-sizing: border-box;
      padding: 0px 20px;
      position: relative;
      .demo-form-inline {
        padding-top: 20px;
      }
      .btn_area {
        text-align: right;
        padding: 20px 0px;
        position: absolute;
        bottom: 0;
        right: 20px;
      }
    }
    .dia_opera {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      box-sizing: border-box;
      padding: 30px;
      height: 100%;
      .errIcon {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
    }
  }
</style>